@import '~antd/dist/antd.css';

div.main {
  padding: 2em;
}

div.main.public {
  padding: 0;
}

div.main.public h1 {
  font-size: 2.7em;
  font-weight: bold;
}

div.main.public .splash {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #1890ff;
}

div.main.public .splash img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  z-index: 1;
}

div.main.public .icon {
  position: fixed;
  bottom: 1em;
  left: 2em;
  height: 8em;
  width: 8em;
}

div.main.public article {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 40em;
  max-width: 100%;
  background: white;
  padding: 2.5em;
}

@media screen and (min-height: 55em) {
  div.main.public article {
    justify-content: center;
  }
}

div.main.public article h1,
div.main.public article form {
  padding: 1rem;
}

div.main.public article label,
div.main.public article button {
  font-weight: bold;
}

div.main.public article .disclaimer {
  padding: 2rem 1rem;
  text-align: justify;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 20px;
  background: #005586;
  background: -webkit-radial-gradient(top left,ellipse,#005586 0,#00446b 100%);
  background: radial-gradient(ellipse at top left,#005586 0,#00446b 100%);
}

header.dev-header {
  background-image: linear-gradient(140deg, #4f4f4f 25%, #303030 25%, #303030 50%, #4f4f4f 50%, #4f4f4f 75%, #303030 75%, #303030 100%);
  background-size: 62.23px 52.22px;
}

header.admin-header {
  background-image: linear-gradient(140deg, #a30000 25%, #6b0000 25%, #6b0000 50%, #a30000 50%, #a30000 75%, #6b0000 75%, #6b0000 100%);
  background-size: 62.23px 52.22px;
}

header.site-down {
  background: #900;
  font-weight: bold;
  justify-content: center;
  color: white;
}


menu {
  padding: 0;
  margin: 0;
}

menu .ant-menu > .ant-menu-item > a {
  display: inline;
}

.ant-steps-item:not(.ant-steps-item-disabled) .ant-steps-item-title {
  text-decoration: underline;
  font-weight: bold;
}

.form-value .ant-btn {
  margin-left: 1em;
}

@media screen and (max-width: 45em) {
  .form-field .ant-btn {
    display: block;
    margin-bottom: 1em;
    margin-left: 0;
    width: 100%;
  }
}


.form-value .ant-btn:first-child {
  margin-left: 0;
}

form .form-field,
.form-field p {
  margin-bottom: 2em;
}

@media screen and (min-width: 45em) {
  .form-field {
    display: flex;
    align-items: top;
  }
  
  .form-label {
    width: 36vw;
    margin-right: 2em;
    text-align: right;
  }
}

.form-label label {
  font-weight: bold;
}

.form-label .form-help {
  font-size: 0.9em;
}

.form-label .form-required {
  color: red;
  font-size: 1.1em;
}

.form-error {
  color: red;
}

.form-value {
  width: 64vw;
}

.form-value .ant-radio-wrapper,
.form-value .ant-checkbox-wrapper {
  display: block;
  margin: 0.25em 0 0.75em;
}

.form-value textarea.ant-input {
  min-height: 6em;
}

.ant-page-header + div.main {
  padding-top: 0;
}

.align-right {
  text-align: right;
}

.wizard {
  max-width: 64em;
  margin: 1em auto;
}

.ant-list-item-action {
  max-width: 100%;
}

.ant-page-header-heading-title {
  margin-bottom: 0.5em;
}

.ant-page-header-heading-extra button {
  margin-left: 0.5em;
}

@media screen and (max-width: 45em) {
  .ant-page-header-heading-extra button {
    display: block;
    margin-left: 0;
    width: 100%;
    padding: 0 0.5em;
  }
}